import React from "react"
import { observer } from "mobx-react"

import { devicesStore         } from 'store/DevicesStore'
import { controlPanelStore    } from "store/ControlPanelStore"
import { controllerStateStore } from "store/ControllerStateStore"

import { Box, Heading         } from "grommet"
import { IndicationBlock      } from "./components/IndicationBlock"
import { CurrentBlock         } from "./components/CurrentBlock"

export const IndicationBlocks = observer (() => {

   if (controllerStateStore.state?.length > 0) {

      return (

         <Box
            border = { { color: 'brand', size: 'xsmall' } }
            style  = { { gap: '24px' } }
            margin = { { bottom: "medium" } }
            pad    = 'medium'
            round
         >
            <Heading
               level  = { 3 }
               margin = { { vertical: 'none' } }
            >
               Текущие показатели
            </Heading>

            { devicesStore.tags?.length > 0 &&

               <Box
                  direction = 'row'
                  flex      = { { grow: 1, shrink: 1 } }
                  style     = { { gap: '24px' } }
                  wrap
               >

                  { devicesStore.tags.map (tag => 
                     <CurrentBlock
                        key = { tag.id_tag }
                        tag = { tag }
                     />
                  ) }
               </Box>
            }

            { (controlPanelStore.currentBlocks?.length > 0  &&
               controlPanelStore.currentBlocks?.find (block =>
                  controllerStateStore.state.find (stat => stat.name == block.id)
               )) &&

                  <Box
                     direction = 'row'
                     flex      = { { grow: 1, shrink: 1 } }
                     style     = { { gap: '24px' } }
                     wrap
                  >

                     { controlPanelStore.currentBlocks.map (block => 
                        <IndicationBlock
                           key   = { block.id }
                           block = { block }
                        />
                     ) }
                  </Box>
            }

            { /*  <Box
                  justify   = "end"
                  pad       = { { top: "medium" } }
                  flex      = { { grow: 0, shrink: 0 } }
                  direction = 'row'
               >
                  <Button
                     primary
                     reverse
                     label = "Подробнее"
                     title = "Подробнее"
                  />
               </Box>  */ }
         </Box>
      )}
})
import React from "react";

import { Box, Button, Text } from "grommet";
import { SideBarLink } from "./styles";

type TMainSideBarButtonProps = {
   callback?: () => void,
   icon     : JSX.Element,
   link?    : string,
   name     : string,
};

export const MainSideBarButton = ({
   callback,
   icon,
   link,
   name,
}: TMainSideBarButtonProps) => {

   return (
      <Button
         hoverIndicator
         onClick = { () => callback?.() }
      >
         <SideBarLink 
            to = { link || "" }
         >
            <Box
               pad       = { { horizontal: "small", vertical: "small" } }
               direction = "row"
               align     = "center"
               gap       = "small"
            >
               { icon }
               <Text>
                  { name }
               </Text>
            </Box>
         </SideBarLink>
      </Button>
   );
}

import React from "react";
import { observer } from "mobx-react";
import { settingsStore } from "store/SettingsStore";

import { CheckBox } from "grommet";
import { toast } from "react-toastify";

import { createSchedule } from "../hooks/useSchedule";

export const ToggleSchedule = observer (( {
   idUnit,
   idTask
}: {
   idUnit: number,
   idTask  : number
} ) => {
   
   const schedule = { ...settingsStore.schedules.find (task => task?.Id === idTask) }

   const saveSettingsFromToggle = (value: boolean) => {

      if (createSchedule (idUnit, { ...schedule, IsEnable: value })) {

         toast.success  (
            settingsStore.settings
            .find (unitSetting => unitSetting.settings.Schedule
            .some (task => task?.Id == idTask)).error_text ?? 'Расписание обновлено'
         )
      }
   }

   return (
      <CheckBox
         checked  = { schedule?.IsEnable }
         onChange = { e =>  saveSettingsFromToggle (e.target.checked) }
         toggle
      />
   )
})
interface FetchInterceptorResponse extends Response {
   request: Request;
}

interface FetchInterceptor {
   request?(url: string, config: any): Promise<any[]> | any[];
   requestError?(error: any): Promise<any>;
   response?(response: FetchInterceptorResponse): FetchInterceptorResponse;
   responseError?(error: any): Promise<any>;
}

let interceptors = []

const interceptor = (fetch, ...args) => {

   const reversedInterceptors = interceptors.reduce ((array, interceptor) => [interceptor].concat(array), [])
   let promise = Promise.resolve (args)

   // Регистрируем перехватчик запросов
   reversedInterceptors.forEach (({ request, requestError }) => {
      if (request || requestError) {
         promise = promise.then (args => request(...args), requestError);
      }
   });

   // Регистрируем вызов fetch
   promise = promise.then (args => {

      //@ts-ignore
      const request = new Request (...args)

      return fetch (request)
      .then (response => {

         response.request = request
         return response
      })
      .catch (error => {

         error.request = request
         return Promise.reject (error)
      })
   })

   // Регистрируем перехватчики ответов
   reversedInterceptors.forEach (({ response, responseError }) => {
      if (response || responseError) {
         promise = promise.then (response, responseError);
      }
   })

   return promise
}

export const fetchInterceptor = (env) => {

   env.fetch = (fetch => ((...args) => (interceptor (fetch, ...args)))) (env.fetch)

   return {

      register: (interceptor) => {

         interceptors.push (interceptor)

         return () => {
            const idx = interceptors.indexOf (interceptor);
            if (idx >= 0) interceptors.splice (idx, 1);
         }
      },
      clear: () => interceptors = []
   }
}
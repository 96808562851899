export const intervalOptions = [
   {
      id: 1,
      label: 'День'
   },
   {
      id: 2,
      label: 'Неделя'
   },
   {
      id: 3,
      label: 'Месяц'
   },
   {
      id: 4,
      label: 'Указать интервал'
   }
]
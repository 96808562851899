import { makeAutoObservable } from "mobx";

import { auth, refresh } from "api/auth";
import { TAuthRequest } from "models/Auth";
import { rootStore } from "store/RootStore";

import {
   getLocalStorageItem,
   removeLocalStorageItem,
   setLocalStorageItem,
} from "utils/localStorage";

import { ACCESS_TOKEN, REFRESH_TOKEN } from "constants/globalConstants";
import { isOuter } from "data/routes";

export class AuthStore {

   loading = false;

   constructor () {
      makeAutoObservable (this);
   }

   async login (formData: TAuthRequest) {

      this.setLoading (true);

      return await auth (formData)
      .then (result => {

         setLocalStorageItem (ACCESS_TOKEN, result.token)
         setLocalStorageItem (REFRESH_TOKEN, result.refresh)

         if (getLocalStorageItem (ACCESS_TOKEN , null) &&
             getLocalStorageItem (REFRESH_TOKEN, null)) {
            rootStore.currentUserStore.getCurrentUserData ();
         }
      })
      .finally (() => {
         this.setLoading (false);
      });
   }

   async refresh () {

      this.setLoading (true)

      return await refresh (getLocalStorageItem (REFRESH_TOKEN, null))
      .then (result => {

         setLocalStorageItem (ACCESS_TOKEN, result.token)
         setLocalStorageItem (REFRESH_TOKEN, result.refresh)

         if (getLocalStorageItem (ACCESS_TOKEN , null) &&
             getLocalStorageItem (REFRESH_TOKEN, null)) {
            rootStore.currentUserStore.getCurrentUserData ()
         }
      })
      .finally(() => {
         this.setLoading (false)
      })

   }

   logout () {

      removeLocalStorageItem (ACCESS_TOKEN)
      removeLocalStorageItem (REFRESH_TOKEN)
      if (!isOuter (window.location.pathname)) window.location.replace ("/login")
   }

   setLoading = (isLoading: boolean) => {
      this.loading = isLoading;
   };
}

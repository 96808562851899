import { api } from "api/client";
import { TChartSample } from "models/Sample";

export const getTagChart = async (
   tagId    : number,
   DBegin   : string | string[],
   DEnd     : string | string[],
   fullData?: boolean,
   groupData?  : boolean
): Promise<TChartSample[]> => {
   return await api.send ('GET', `/tag/chart/?id_tag=${tagId}&dbegin=${DBegin}&dend=${DEnd}${fullData ? '&fulldata' : ''}${groupData ? '&groupData' : ''}`);
};
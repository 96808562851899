import React, { useState, useEffect, useCallback } from 'react'
import { observer  } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { TCustomBlock         } from 'models/ControlPanel'
import { TTarget              } from 'models/ControllerState'

import { controllerStateStore } from 'store/ControllerStateStore'
import { controlPanelStore    } from 'store/ControlPanelStore'

import {
   Card,
   CardHeader,
   Heading,
} from 'grommet'
import { TargetScheduleValue  } from './components/TargetScheduleValue'
import { TargetScheduleEnable } from './components/TargetScheduleEnable'

//import { setControl } from 'components/Library/ControlFabric/hooks/setControl'

export const TargetSchedule = observer (({ block }: { block: TCustomBlock }) => {

   const { idProject } = useParams ()

   const [edit       , setEdit       ] = useState (false)
   const [targetValue, setTargetValue] = useState <TTarget> ()
   const [disabled   , setDisabled] = useState (false)

   const stat = controllerStateStore.state.find (option => option.name == block.id)?.value

   const setTarget = useCallback (async (target: TTarget = targetValue) => {

      const command = block.controls
      .flatMap (control => control.options)
      .find    (option => /Значение/.test (option.label)).command

      controllerStateStore.setCommand (Number (idProject), block.alias, command, undefined, target)

      /* setControl ({
         name: controlPanelStore.getControlBlockByCommand    (command)?.name
         ??    controlPanelStore.getIndicationBlockByCommand (command)?.name,
         command: command,
         value  : stat
      }, setDisabled) */

      setEdit (false)

   }, [idProject, controlPanelStore.controlBlocks, controlPanelStore.targetBlocks, block.alias, targetValue])

   useEffect (() => {

      if (stat) {
         setTargetValue ({
            isenable: (stat & 0x80000000) ? true : false,
            time1   : (stat >> 24) & 0x7F,
            value1  : (stat >> 16) & 0xFF,
            time2   : (stat >> 8 ) & 0xFF,
            value2  : stat & 0xFF
         })
      }
   }, [controllerStateStore.state, block, stat])

   return (
      <Card
         pad    = "medium"
         border = { { color: 'brand', size: 'xsmall' } }
         flex   = 'grow'
      >
         <CardHeader
            justify = "center"
         >
            <Heading
               level  = { 3 }
               margin = { { top: 'xxsmall', bottom: 'none' } }
            >
               { block.label }
            </Heading>
         </CardHeader>

         
         { !edit &&
               <TargetScheduleEnable
                  block           = { block }
                  targetValue     = { targetValue }
                  setEdit         = { setEdit }
                  sendCommand     = { setTarget }
                  disabled        = { disabled }
               />
         }

         { edit &&
            <TargetScheduleValue
               targetValue    = { targetValue }
               setEdit        = { setEdit }
               setTargetValue = { setTargetValue }
               sendCommand    = { setTarget }
               disabled       = { disabled }
            />
         }

      </Card>
   )
})
import { makeAutoObservable } from "mobx";

type TCorrelation = {
   render   : boolean,
   source   : string,
   target   : string,
   style?   : object,
   class?   : string,
   disabled?: boolean
}

class CorrelationsStore {

   correlations: TCorrelation[] = []

   constructor () { makeAutoObservable (this) }

   setCorrelation (source: string, func: string) {

      const correlations: TCorrelation[] = eval ("(" + func + ")")

      if (correlations) {

         correlations.forEach (correlation => {

            const idx = this.correlations
            .flatMap (correlation => correlation?.target)
            .indexOf (correlation?.target)

            if (idx === -1) this.correlations.push   (correlation)
            else            this.correlations.splice (idx, 1, correlation)
         })
      }

      this.correlations = this.correlations.slice ()
   }
}

export const correlationsStore = new CorrelationsStore()

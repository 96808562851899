import { rootStore } from "store/RootStore"
import { fetchInterceptor } from "utils/fetchInterceptor"
import { getLocalStorageItem } from "utils/localStorage"
import { ACCESS_TOKEN } from "constants/globalConstants"
import { TError } from "models/Error"
import { toast } from "react-toastify"

interface IDataRequest {
   [key: string]: Request
}

class ApiClient {
   url: string
   interceptors

   constructor (url = "") {
      this.url = url
      this.interceptors = ApiClient.createInterceptors ().getInstance ()
   }

   private static setHeaders (
      options: Record<string, string> = {}
   ): Record<string, string> {

      return {
         "Content-Type": "application/json",
         ...options,
      }
   }

   private static setAuthorizationHeader () {

      const accessToken = getLocalStorageItem (
         ACCESS_TOKEN,
         null
      )

      if (accessToken) return { Authorization: `Bearer ${accessToken}` }
   }

   private static setBody (data?: object) {

      if (data instanceof FormData) return data
      return typeof data === "object" ? JSON.stringify (data) : undefined
   }

   private static createInterceptors () {

      let instance

      const init = () => {
         let isRefreshing       = false
         let refreshSubscribers = []
         let dataRequests       = {} as IDataRequest

         const logoutUser = () => {
            rootStore.authStore.logout ()
         }

         const subscribeTokenRefresh = callback => {
            refreshSubscribers.push (callback)
         }

         const onRefreshed = () => {
            refreshSubscribers.map (callback => callback ())
            refreshSubscribers = []
         }

         const removeDataRequestsItem = requestKey => {
            const { [requestKey]: _omit, ...remaining } = dataRequests
            dataRequests = remaining
         }

         const getRelativeUrl = url => url.replace (window.location.origin, '');

         return {

            registerInterceptors: () => {

               fetchInterceptor (window).register ({

                  request (url, config) {

                     if (config && (url.indexOf ('/auth/') === -1 || config.method == 'PUT')) {

                        dataRequests = {
                           ...dataRequests,
                           [`${getRelativeUrl(url)}_${config.method || 'GET'}`]: config,
                        }
                     }
                     return [url, config]
                  },

                  response (response) {

                     const requestKey = `${getRelativeUrl (response.url)}_${response.request.method}`

                     if (response.status === 401 && (response.url.indexOf ('/auth/') === -1 || response.request.method == 'PUT')) {

                        if (!isRefreshing) {

                           isRefreshing = true

                           rootStore.authStore.refresh ()
                           .then    (() => onRefreshed ())
                           .catch   (() => logoutUser ())
                           .finally (() => isRefreshing = false)
                        }

                        const retryOrigReq: any = new Promise ((resolve, reject) => {

                           const data = {
                              ...dataRequests[requestKey],
                              headers: ApiClient.setHeaders ({
                                 ...ApiClient.setAuthorizationHeader (),
                              })
                           }

                           subscribeTokenRefresh (() => {

                              fetch (response.url, data)
                              .then (origReqResponse => {
                                 resolve (origReqResponse)
                                 removeDataRequestsItem (requestKey)
                              })
                              .catch (err => { reject (err) })
                           })
                        })

                        return retryOrigReq
                     }

                     removeDataRequestsItem (requestKey)
                     return response
                  },
               })
            },
         }
      }

      return {
         getInstance () {
            if (!instance) instance = init()
            return instance
         }
      }
   }

   public async send<T> (
      method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
      path: string,
      data?: object,
      headers?: Record<string, string>
   ): Promise<T> 
   {

      await this.interceptors.registerInterceptors ()

      try {
         const response = await fetch (`${this.url}${path}`, {
            method,
            //credentials: 'include',
            body: ApiClient.setBody (data),
            headers: ApiClient.setHeaders ({
               ...headers,
               ...ApiClient.setAuthorizationHeader (),
            }),
         })

         if (response.status === 204) return

         try {

            const result = await response.json ()

            if  ([400, 402, 403, 404].includes (response.status)) {

               this.handleError (result?.error_text || 'Что-то пошло не так...')
               return Promise.reject (result)
            }

            return result

         } catch { 
            if (response.url.includes ('/auth/')) throw 'Необходимо авторизоваться' 
            else                                  throw 'Ответ сервера не может быть разобран' 
         }
      } catch (err) { this.handleError (err) }
   }

   private handleError = (error: TError | string | unknown) => {
      const message = (typeof error === "string" ? error : (error as TError)?.error_text) || "Что-то пошло не так..."
      toast.error(message)
   }
}

export const api = new ApiClient (process.env.REACT_APP_API_BASE)

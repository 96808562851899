import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { TGroup            } from 'models/ControlPanel'
import { controlPanelStore } from 'store/ControlPanelStore'

import { Group             } from './components/Group'

export const Groups = observer (() => {
   const [groups, setGroups] = useState <TGroup[]> ([])

   useEffect (() => {
      if (controlPanelStore.groups?.length > 0)
         setGroups (controlPanelStore.groups)
   }, [controlPanelStore.groups])

   if (groups?.length > 0) {
      return (
         <>
            { controlPanelStore.groups.map ((group: TGroup) => 
               <Group
                  key   = { group.id }
                  block = { group }
               />
            ) }
         </>
      )
   } else return ('Группы не обнаружены')
})
import React from 'react'
import { observer } from 'mobx-react'

import { TControlBlock, TCustomBlock, TIndicationBlock } from 'models/ControlPanel'
import { TGroup            } from 'models/ControlPanel'

import { Box, Heading      } from 'grommet'
import { useCorrelation    } from 'hooks/useCorrelation'
import { useBlocks         } from 'hooks/useBlocks'

export const Group = observer (({ block } : { block: TGroup }) => {

   const {
      style,
      className
   } = useCorrelation (block)

   if (block?.label != undefined) {

      return (

         <Box 
            direction = 'column'
            pad       = "medium"
            flex      = 'grow'
            fill      = 'horizontal'
            border    = { { color: 'brand', size: 'xsmall' } }
            style     = { { ...style, gap: '24px' } }
            className = { className }
            round
         >

            { block?.label &&
               <Heading
                  level  = { 3 }
                  margin = { { vertical: 'none' } }
               >
                  { block?.label }
               </Heading>
            }

            <Box 
               direction = 'row'
               flex      = { { grow: 1, shrink: 1 } }
               style     = { { gap: '24px' } }
               wrap
            >
               { block.group?.map ((block: TControlBlock | TCustomBlock | TIndicationBlock | TGroup) => {
                  return useBlocks (block)
               }) }
            </Box>
         </Box>
      )}
   else {

      return (

         <Box 
            direction = 'row'
            flex      = { { grow: 1, shrink: 1 } }
            style     = { { ...style, gap: '24px' } }
            fill      = 'horizontal'
            className = { className }
            round
            wrap
         >
            { block.group?.map ((block: TControlBlock | TCustomBlock | TIndicationBlock | TGroup) => {
               return useBlocks (block)
            }) }
         </Box>
      )
   }
})
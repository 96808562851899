import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { controllerStateStore } from 'store/ControllerStateStore'
import { TControl             } from 'models/ControlPanel'

import { Text                 } from 'grommet'
import { useCorrelation       } from 'hooks/useCorrelation'
import { toast                } from 'react-toastify'

export const ControlText = observer (({ control }: { control: TControl }) => {

   const [state, setState] = useState <object> ()
   const {
      render,
      style,
      className
   } = useCorrelation (control)

   useEffect (() => {
      setState (controllerStateStore.state.reduce ((acc, stat) => {
         acc[stat.name] = stat.value
         return acc
      }, {}))
   },[controllerStateStore.state])

   if (control.options.length > 0 && state && render) return (

      <Text
         alignSelf = 'center'
         style     = { style }
         className = { className }
      >
         { control.options
         .map ((option, i) => {

            try {
               return <span key = { i }>
                  { eval (option.text) (state) }
               </span>
            } catch (e) {
               return toast.warning (e)
            }})
         }
      </Text>
   )
})
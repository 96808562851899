import { TRoutes } from 'models/Routes'

import { Main } from 'containers/Main/Main'
import { Login } from 'containers/Auth/Login'
import { Page404 } from 'components/Common/Page404'
import { ControlPanel } from 'components/ControlPanel'

const routes: TRoutes = [
   {
      path     : '/login',
      component: Login,
      isOuter  : true,
   },
   {
      path     : '/',
      component: Main,
   },
   {
      path     : '/:IdProject',
      component: ControlPanel,
   },
   {
      path     : '*',
      component: Page404
   },
]

export const isOuter = (pathname: string): boolean => {

   return routes
   .filter (route => route.isOuter)
   .some   (route => route.path === pathname)
}
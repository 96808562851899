import { makeAutoObservable } from "mobx"
import { rootStore          } from "./RootStore"
import { devicesStore       } from "./DevicesStore"

import { getControllerState, setControllerCommand       } from "api/controller"
import { TControllerState, TStateRequestResult, TTarget } from "models/ControllerState"

import { toast        } from "react-toastify"
import { errorHandler } from "utils/errorHandler"
import {  STATE_TIMER } from "constants/globalConstants"

class ControllerStateStore {

   loading  = false
   idProject: number
   location : string
   state    : TControllerState[] = []
   intervals: {
      [k: string]: ReturnType<typeof setTimeout>
   } = {}

   constructor () { makeAutoObservable (this) }

   getState (idProject: number)
   {
      this.setLoading (true)

      if (this.idProject != idProject) {

         this.idProject = idProject
         Object.values (this.intervals).forEach (interval => clearInterval (interval))
         this.state = []
      }

      devicesStore.units.forEach (unit => {
         if (unit?.alias) this.getStateCommand (this.idProject, unit.alias)
      })
   }

   setControllerState (controllerState: TStateRequestResult)
   {
      if (controllerState?.code == 0) {

         controllerState.data.forEach (control => {

            const idx = this.state.flatMap (stat => stat.name).indexOf (control.name)

            if (idx === -1) this.state.push   (control)
            else            this.state.splice (idx, 1, control)

            this.state = this.state.slice ()
         })
      } else errorHandler (controllerState.message)

      if (Object.keys (this.intervals).length == 0) {

         devicesStore.units.forEach (unit => {

            if (unit?.alias)
               this.intervals[unit.alias] = setInterval (async () => {

                  if (rootStore.currentUserStore.currentUserData &&
                  this.idProject != null) {
                     this.getStateCommand (this.idProject, unit.alias)
                  }
                  else Object.values (this.intervals).forEach (interval => clearInterval (interval))

               }, (STATE_TIMER))
         })
      }

      this.setLoading (false)
   }

   async getStateCommand (idProject: number, alias: string)
   {
      try {
         const state = await getControllerState (idProject, alias)
         this.setControllerState (state)
      }
      catch (err) {
         if (!err.error_text) errorHandler (err)
         this.setLoading (false)
      }
   }

   async setCommand (
      project: number,
      alias  : string,
      command: number,
      value? : number,
      target?: TTarget
   ) {

      const result = await setControllerCommand (
         project,
         alias,
         command, 
         value,
         target
      )
      
      if (result.Code == 0) toast.success (result.Message)
      else                  toast.error   (result.Message)

      this.getStateCommand (project, alias)
   }

   setLoading (isLoading: boolean)
   {
      this.loading = isLoading
   }

   setLocation (location: string)
   {
      this.location = location
   }
}

export const controllerStateStore = new ControllerStateStore ()
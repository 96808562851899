import React from "react"

import { Box, Button, CardBody, CardFooter, FormField, Heading, TextInput } from "grommet"

//import { ControlFabric } from "components/Library/ControlFabric/ControlFabric"
import { TTarget } from "models/ControllerState"

export const TargetScheduleValue = ({
   targetValue,
   setTargetValue,
   setEdit,
   sendCommand,
   disabled
}: {
   targetValue   : TTarget,
   setTargetValue: React.Dispatch<React.SetStateAction<TTarget>>
   setEdit       : (boolean) => void
   sendCommand   : () => void,
   disabled      : boolean
}) => {


   return (
      <>
         <CardBody
            margin    = { { vertical: "xsmall" } }
            direction = "row"
            style     = { { gap: '24px' } }
         >
            <Box fill>
               <Heading level = { 3 }>
                  Интервал 1
               </Heading>

               <FormField
                  label   = "Время начала"
               >
                  <TextInput
                     placeholder = 'Установите время начала'
                     type        = 'number'
                     value       = { targetValue?.time1 }
                     onChange    = { event => setTargetValue (prev => ( { ...prev, time1: Number (event.target.value) }) ) }
                     min         = { 0 }
                     max         = { 24 }
                  />
               </FormField>
            
               <FormField
                  label   = "Целевая температура"
               >
                  <TextInput
                     placeholder = 'Установите целевую температуру'
                     type        = 'number'
                     value       = { targetValue?.value1 }
                     onChange    = { event => setTargetValue (prev => ( { ...prev, value1: Number (event.target.value) }) ) }
                  />
               </FormField>
            </Box>

            <Box fill>
               <Heading level = { 3 }>
                  Интервал 2
               </Heading>

               <FormField
                  label   = "Время начала"
               >
                  <TextInput
                     placeholder = 'Установите время начала'
                     type        = 'number'
                     value       = { targetValue?.time2 }
                     onChange    = { event => setTargetValue (prev => ( { ...prev, time2: Number (event.target.value) }) ) }
                     min         = { 0 }
                     max         = { 24 }
                  />
               </FormField>
            
               <FormField
                  label   = "Целевая температура"
               >
                  <TextInput
                     placeholder = 'Установите целевую температуру'
                     type        = 'number'
                     value       = { targetValue?.value2 }
                     onChange    = { event => setTargetValue (prev => ( { ...prev, value2: Number (event.target.value) }) ) }
                  />
               </FormField>
            </Box>
         </CardBody>
         
         <CardFooter justify = 'between'>
            <Button
               secondary
               label   = 'Отмена'
               margin  = { { top: 'small' } }
               onClick = { () => setEdit (false) }
            />

            { /* <ControlFabric
               disabled = { disabled }
            > */ }
            <Button
               primary
               label   = 'Применить'
               margin  = { { top: 'small' } }
               onClick = { () => sendCommand () }
            />
            { /* </ControlFabric> */ }
         </CardFooter>
      </>
   )
}
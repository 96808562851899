import React from 'react'

import { Box, Button          } from 'grommet'
import { controllerStateStore } from 'store/ControllerStateStore'
import { TControl             } from 'models/ControlPanel'

import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { observer             } from 'mobx-react'
import { useWaitCommand       } from 'hooks/useWaitCommand'
import { useCorrelation       } from 'hooks/useCorrelation'

export const ControlButtonsGroup = observer (({
   control,
   sendCommand,
   watchdog
}: {
   control    : TControl,
   sendCommand: (command: number, value?: number) => void,
   watchdog   : boolean
}) => {

   const { controlCommand, wait } = useWaitCommand (sendCommand)
   const {
      style,
      className,
      disabled
   } = useCorrelation (control)

   return (
      <ControlFabric wait = { wait } >
         <Box
            direction = 'row'
            flex      = 'grow'
            wrap
         >
            { control.options
            .map (option => (

               <Button
                  key       = { option.state }
                  size      = "large"
                  primary   = { controllerStateStore?.state
                  ?.find (state => state.name == control.id)?.value == option.state ? true : false }
                  label     = { controllerStateStore?.state
                  ?.find (state => state.name == control.id)?.value ? (option?.labelOn || option.label) : (option?.labelOff || option.label) } 
                  onClick   = { () => watchdog ? controlCommand (option.command) : sendCommand (option.command) }
                  fill      = 'horizontal'
                  disabled  = { disabled }
                  style     = { {
                     ...style,
                     borderRadius: 0,
                     pointerEvents: option.command ? 'auto' : 'none'
                  } }
                  className = { className }
               />
            )) }
         </Box>
      </ControlFabric>
   )
})

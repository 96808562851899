import { useCallback, useState } from "react"
import { setControl            } from "components/Library/ControlFabric/hooks/setControl"
import { controlPanelStore     } from "store/ControlPanelStore"

export const useWaitCommand = (sendCommand: (c: number, v?: number) => void) => {

   const [wait, setWait] = useState (false)

   const controlCommand = useCallback (async (command: number, value?: number) => {

      sendCommand (command, value)

      setControl ({
         name: controlPanelStore.getControlByCommand         (command)?.id
         ??    controlPanelStore.getIndicationBlockByCommand (command)?.id,
         command: command,
         value  : value
      }, setWait)
   }, [controlPanelStore.controlBlocks, controlPanelStore.targetBlocks])

   return { controlCommand, wait }
}
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { Box, Button, CheckBox, DateInput, ResponsiveContext, Select, Tip } from 'grommet'
import { getTagChart } from 'api/tagchart'
import { TTag } from 'models/Tag'
import { Refresh, Time } from 'grommet-icons'

import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Tooltip,
   LogarithmicScale,
   ChartData,
   Point,
   ScaleOptionsByType,
   CartesianScaleTypeRegistry,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import zoomPlugin from 'chartjs-plugin-zoom'
import { intervalOptions } from 'data/chartInterval'
import { TChartSample } from 'models/Sample'
import { devicesStore } from 'store/DevicesStore'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'
import { Loader } from 'components/Common/Loader'

ChartJS.register (
   CategoryScale,
   LinearScale,
   LogarithmicScale,
   PointElement,
   LineElement,
   Tooltip,
   zoomPlugin
)

//const data = [{ date: '2020-08-20', amount: 2 }, { date: '2020-08-21', amount: 47 }, { date: '2020-08-22', amount: 33 }];

const getLocaleDate = (date: Date): string => {

   return date.toLocaleString('sv-SE', {
      year  : 'numeric',
      month : '2-digit',
      day   : '2-digit',
      hour  : '2-digit',
      minute: '2-digit'
   }).replace(" ", "T")
}

let loading = false

export const IndicationChart = observer (({ tag }: { tag: TTag }) => {

   const size = useContext (ResponsiveContext)

   const [interval , setInterval ] = useState <number>()
   const [fullData , setFullData ] = useState <boolean>(true)
   const [groupData, setGroupData] = useState <boolean>(false)

   const [dBegin, setDBegin] = useState <string | string[]> (getLocaleDate (new Date()))
   const [dEnd  , setDEnd  ] = useState <string | string[]> (getLocaleDate (new Date()))

   const [options, setOptions] = useState <{
      labels  : ChartData['labels'],
      datasets: ChartData <"line", (number | Point)[]> ['datasets'],
      scales  : _DeepPartialObject<{ [key: string]: ScaleOptionsByType<keyof CartesianScaleTypeRegistry>; }>
   }> ()
 
   const getChart = useCallback (() => {

      loading = true

      getTagChart (
         tag.id_tag,
         dBegin,
         dEnd,
         fullData,
         groupData
      )
      .then (result =>  {

         const data: {[key: string]: TChartSample[]} = {}
         let oldSample: TChartSample = null

         result.forEach (sample => {

            if (!data[sample.id_tag]) data[sample?.id_tag] = []

            if (devicesStore.tags.find (tag => tag.id_tag == sample.id_tag).id_tagtype == 5) {

               if (oldSample && sample.id_tag == oldSample.id_tag && sample.sample) {

                  switch (interval) {
                     case 1:
                        if (fullData) {
                           data[sample.id_tag].push ({
                              sample : oldSample.sample,
                              dsample: new Date (new Date (sample.dsample).setMinutes (new Date (sample.dsample).getMinutes () - 1)).toLocaleString('sv-SE', {
                                 year  : 'numeric',
                                 month : '2-digit',
                                 day   : '2-digit',
                                 hour  : '2-digit',
                                 minute: '2-digit',
                                 second: '2-digit',
                              }) + "." + new Date (sample.dsample).getMilliseconds (),
                              id_tag : sample.id_tag
                           })
                        }
                        else {
                           data[sample.id_tag].push ({
                              sample : oldSample.sample,
                              dsample: new Date (new Date (sample.dsample).setHours (new Date (sample.dsample).getHours () - 1)).toLocaleString ('sv-SE', {
                                 year  : 'numeric',
                                 month : '2-digit',
                                 day   : '2-digit',
                                 hour  : '2-digit',
                                 minute: '2-digit',
                              }),
                              id_tag : sample.id_tag
                           })
                        }
                        break

                     default:
                        /* data[sample.id_tag].push ({
                           sample : oldSample.sample,
                           dsample:  new Date (new Date (sample.dsample).setDate (new Date (sample.dsample).getDate () - 1)).toLocaleString ('sv-SE', {
                              year  : 'numeric',
                              month : '2-digit',
                              day   : '2-digit',
                              hour  : '2-digit',
                              minute: '2-digit',
                           }),
                           id_tag : sample.id_tag
                        }) */
                  }
               }

               oldSample = sample
            }

            data[sample.id_tag].push (sample)
         })

         if (Object.keys (data)?.length > 0) {

            const datum = Object.values (data)?.flatMap (samplesGroup => samplesGroup).flatMap (({ dsample }) => dsample)
            .sort ((a, b) => new Date (a).getTime () - new Date (b).getTime ())
            const dates = datum.map (label => {
   
               switch (interval) {
   
                  case 1:
                     if (fullData) return new Date (label).toLocaleTimeString ('ru-RU')
                     else          return new Date (label).toLocaleString ('ru-RU', { hour: 'numeric' })
   
                  default:
                     return new Date (label).toLocaleDateString ('ru-RU')
               }
            })
            const labels = [...new Set (dates)]

            const datasets = Object.entries (data).map (([key, samplesGroup]) => {
               //console.log ('labels:', labels)
               //console.log ('samples:', samplesGroup)
               //console.log ('data:', datum.map (dsample => samplesGroup.find (sample => sample.dsample == dsample)?.sample || null))
               const color = devicesStore.tags.find (({ id_tag }) => id_tag == Number (key)).color

               return {
                  data           : datum.map (dsample => samplesGroup.find (sample => sample.dsample == dsample)?.sample || null),
                  borderColor    : color,
                  backgroundColor: color,
                  yAxisID        : 'y' + key,
               }
            })
            //console.log (datasets)
            const scales = Object.keys (data).reduce ((acc, key, i) => {

               return {
                  ...acc,
                  ['y' + key]: {
                     type    : 'linear' as const,
                     display : true,
                     position: (i == 0) ? 'left' : 'right' as const,
                     grid    : { drawOnChartArea: false },
                  }
               }
            }, {})

            if (labels && datasets && scales) {
               
               setOptions ({
                  labels,
                  datasets,
                  scales
               })
            }

            loading = false
         }
      })
   }, [dBegin, dEnd, fullData, groupData])

   useEffect(() => {
      setInterval (1)
   }, [])

   useEffect (() => {

      switch (interval) {
         case 1: {
            const date = new Date ()
            setDEnd (getLocaleDate (date))
            date.setDate (date.getDate () - 1)
            setDBegin (getLocaleDate (date))
            break;
         }
         case 2: {
            const date = new Date ()
            setDEnd (getLocaleDate (date))
            date.setDate (date.getDate () - 7)
            setDBegin (getLocaleDate (date))
            break;
         }
         case 3: {
            const date = new Date ()
            setDEnd (getLocaleDate (date))
            date.setMonth (date.getMonth () - 1)
            setDBegin (getLocaleDate (date))
            break;
         }
         default:
            break;
      }

   }, [interval])

   useEffect(() => {
      getChart ()
   }, [dBegin, dEnd, fullData, groupData])

   //console.log (interval)
   //console.log (dBegin)
   //console.log (options)

   return (
      <Box
         width     = 'large'
         height    = 'medium'
         direction = 'column'
         justify   = 'between'
      >
         
         <Box flex = 'shrink'>

            { tag?.ngroup &&
            
               <Box
                  margin = { { bottom: 'medium' } }
               >
                  <CheckBox
                     label    = { size != 'small' 
                                 ? <span style = { { fontSize: 'larger' } }>{ 'Отобразить данные группы параметров' }</span>
                                 : 'Группа параметров' }
                     checked  = { groupData }
                     onChange = { e => setGroupData (e.target.checked) }
                     toggle
                  />
               </Box>
            }

            <Box
               direction = 'row'
               gap       = 'medium'
            >

               <Button 
                  primary
                  icon    = { <Refresh /> }
                  onClick = { getChart }
               />

               <Box fill = 'horizontal'>
                  <Select
                     options  = { intervalOptions }
                     value    = { interval }
                     labelKey = 'label'
                     valueKey = 'id'
                     onChange = { ({ option }) => setInterval (option.id) }
                  />
               </Box>

               {  interval == 1 &&
                  <Tip content = 'Получить подробные данные'>
                     <Box
                        border
                        direction = 'row'
                        align     = 'center'
                        gap       = 'small'
                        flex      = 'grow'
                        style     = { { borderRadius: '12px' } }
                        pad       = { { horizontal: 'small' } }
                     >
                        <Time />
                        <CheckBox
                           checked  = { fullData }
                           onChange = { event => setFullData (event.target.checked) }
                        />
                     </Box>
                  </Tip> 
               }

            </Box>

            { interval == 4 &&
               <Box
                  direction = 'row'
                  gap       = 'small'
                  margin    = { { vertical: 'small' } }
               >
                  <DateInput
                     format   = "dd.mm.yyyy"
                     value    = { dBegin }
                     onChange = { ({ value }) => {setDBegin (value)} }
                     calendarProps = { {
                        locale: "ru-RU",
                        firstDayOfWeek: 1
                     } }
                  />
                  <DateInput
                     format   = "dd.mm.yyyy"
                     value    = { dEnd }
                     onChange = { ({ value }) => {setDEnd (value)} }
                     calendarProps = { {
                        locale: "ru-RU",
                        firstDayOfWeek: 1
                     } }
                  />
               </Box>
            }
         </Box>

         <Box
            flex
            margin = { { top: '20px' } }
         >

            { loading
            ? <Loader />
            :  (options && Object.keys (options)?.length > 0) ? (

               <Line
                  data = { {
                     labels  : options.labels,
                     datasets: options.datasets
                  } }

                  options = { {
                     spanGaps: true,
                     maintainAspectRatio: false,
                     responsive         : true,
                     interaction: {
                        mode     : 'index' as const,
                        intersect: false,
                     },
                     plugins: {
                        legend: { display: false },
                        zoom: {
                           zoom: {
                              wheel: { enabled: true },
                              pinch: { enabled: true },
                              mode : 'x',
                           },
                           pan: {
                              enabled: true,
                              mode   : 'x',
                           },
                        }
                     },
                     scales: options.scales
                  } }
               />

               ) : (
                  <Box>Нет данных для отображения</Box>
               ) 
            }

         </Box>
      </Box>
   )
})
import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { settingsStore } from 'store/SettingsStore'

import { Schedule } from './components/Schedule'
import { Box, Button } from 'grommet'
import { Modal } from 'components/Library/Modal/Modal'
import { ManipulateSchedule } from './components/components/ManipulateSchedule'
import { Loader } from 'components/Common/Loader'

export const Schedules = observer (() => {

   const [activeModal, setActiveModal] = useState (false)

   //console.log (settingsStore.settings)

   if (settingsStore.loading) return <Loader />
   else {
      return (

         <>
            <Box
               direction = "row"
               flex      = { { grow: 1, shrink: 1 } }
               style     = { { gap: '24px' } }
               wrap
            >

               { settingsStore.schedules?.length > 0 &&
                 settingsStore.settings?.map (settings => 

                    <Schedule
                       key     = { settings.id_unit }
                       setting = { settings }
                    />
                 ) 
               }

               <Box
                  margin    = { { top: 'medium' } }
                  justify   = 'end'
                  fill      = 'horizontal'
                  direction = 'row'
               >
                  <Button
                     primary
                     reverse
                     hoverIndicator
                     onClick = { () => setActiveModal (true) }
                     label   = 'Создать задачу'
                     fill    = { false }
                  />
               </Box>
            </Box>

            <Modal
               modal    = { activeModal }
               setModal = { setActiveModal }
               heading  = { 'Новая задача' }
            >
               <ManipulateSchedule
                  idUnit   = { null }
                  idTask   = { null }
                  setModal = { setActiveModal }
               />
            </Modal>
         </>
      )
   }
})
import { useEffect, useState } from "react"

import { TControl, TGroup } from "models/ControlPanel"
import { correlationsStore       } from 'store/CorrelationsStore'
import { controllerStateStore    } from "store/ControllerStateStore"

export const useCorrelation = (
   control: TControl | TGroup,
) : {
   render   : boolean,
   style    : object,
   className: string,
   disabled : boolean
} => {

   const [render   , setRender   ] = useState <boolean> ()
   const [disabled , setDisabled ] = useState <boolean> ()
   const [style    , setStyle    ] = useState <object>  ()
   const [className, setClassName] = useState <string>  ()

   useEffect (() => {

      if (control.type != 'Group') {
         const func = control.options
         .find (option  => option.state == controllerStateStore?.state
         .find (state   => state.name == control.id)?.value)?.func

         if (func)
            correlationsStore.setCorrelation (
               control.id, 
               func
            )
      }
   }, [controllerStateStore.state])

   useEffect (() => {

      const relation = correlationsStore.correlations
      .find (correlation => correlation?.target == control.rel ||
                            correlation?.target == 'All')

      if (relation) {
         setStyle     (relation?.style)
         setClassName (relation?.class)
         setDisabled  (relation?.disabled)
         setRender    (relation?.render)
      }
   }, [correlationsStore.correlations])

   return {
      render,
      style,
      className,
      disabled
   }
}
import React from 'react';
import { Loader } from 'components/Common/Loader';
import { observer } from 'mobx-react';

export const ControlFabric = observer (({
   wait,
   children,
} : {
   wait: boolean,
   children: JSX.Element,
}) => {

   if (wait) return <Loader size = 'small' />
   else      return children
})
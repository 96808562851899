import React from "react"

import { TCustomBlock } from "models/ControlPanel"
import { 
   Button,
   CardBody,
   CardFooter,
   Text
} from "grommet"

export const TargetView = ({
   block,
   targetValue,
   setEdit,
}: {
   block      : TCustomBlock,
   targetValue: number,
   setEdit    : (b: boolean) => void,
}) => {

   return (
      <>
         <CardBody>
            <Text
               textAlign = 'center'
               weight    = 'bold'
               size      = "2xl"
               margin    = { { vertical: "xsmall" } }
            >
               { block.look ? eval (block.look) (targetValue) : targetValue } { block.sign && ' ' + block?.sign }
            </Text>
         </CardBody>

         <CardFooter
            justify = 'between'
            margin  = { { top: "small" } }
         >
            <Button
               primary
               reverse
               label   = "Изменить"
               onClick = { () => setEdit (true) }
               fill    = 'horizontal'
            />
         </CardFooter>
      </>
   )
}
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { Box, Card, CardBody, CardHeader, CheckBox, Heading, ResponsiveContext, RangeInput, Text } from 'grommet'

import { controllerStateStore } from 'store/ControllerStateStore'
import { TCustomBlock } from 'models/ControlPanel'
import { controlPanelStore } from 'store/ControlPanelStore'
import { ControlFabric } from "components/Library/ControlFabric/ControlFabric"
//import { setControl } from "components/Library/ControlFabric/hooks/setControl"
import { useParams } from 'react-router-dom'

export const ToogleRangeBlock = observer (({ block }: { block: TCustomBlock }) => {

   const size = useContext (ResponsiveContext)
   const { idProject } = useParams ()

   const [isEnable, setIsEnable] = useState <boolean>(false)
   const [value   , setValue   ] = useState <number>(0)

   useEffect (() => {

      const stat = controllerStateStore?.state
      ?.find (state => state.name == block.id)?.value

      setIsEnable ((stat & 0x80000000) ? true : false)
      setValue     (stat & 0x7FFFFFFF)
   }, [controllerStateStore?.state])

   const controlCommand = useCallback (async (value, isEnable) => {

      if (isEnable) value |= 0x80000000
      const command = block.command

      controllerStateStore.setCommand (Number (idProject), block.alias, command, value)

   }, [idProject, controlPanelStore.controlBlocks, controlPanelStore.targetBlocks, value, isEnable])

   //console.log (value)

   return (

      <Card
         border = { { color: 'brand', size: 'xsmall' } }
         pad    = { { horizontal: "medium" } }
         width  = { size == 'large' ? 'large' : 'medium' }
         flex   = 'grow'
      >
         <CardHeader>
            <Heading 
               level  = { 3 }
               style = { { whiteSpace: 'nowrap' } }
            >
               { block?.label }
            </Heading>

            { (block && controllerStateStore.state?.length > 0) &&
               block.controls
               .filter (control => control.type == 'Toogle')
               .map    (control => {

                  const option = control.options
                  .find (option  => !!option.state == isEnable)

                  return <ControlFabric
                     key  = { control.id }
                     wait = { false }
                  >
                     <CheckBox
                        label    = { size != 'small' ? <span style = { { textAlign: 'right' } }>{ option?.label }</span> : '' }
                        checked  = { control.look ? eval (control.look) (isEnable) : isEnable }
                        onChange = { () => {
                           controlCommand (value, !option.state)
                           setIsEnable    (!option.state)
                        } }
                        toggle
                        reverse
                     />
                  </ControlFabric>
               })
            }

         </CardHeader>

         <CardBody>

            { (block.controls.some (control => control.type == 'RangeInput') &&
               controllerStateStore.state?.length > 0) &&

               <Box
                  pad       = { { vertical: "medium" } }
                  direction = "column"
                  fill
               >
                  { block.controls
                  .filter (control => control.type == 'RangeInput')
                  .map    (control => {

                     const option = control.options[0]

                     return <ControlFabric
                        key  = { control.id }
                        wait = { false }
                     >
                        <Box>

                           { value != undefined &&
                              <Box
                                 justify = 'end'
                                 fill    = 'horizontal'
                              >
                                 <Text alignSelf = 'center'>
                                    { `${option?.label}: ${value}%` }
                                 </Text>
                              </Box>
                           }

                           <Box
                              margin = { { top: 'medium' } }
                              fill   = 'horizontal'
                           >
                              <RangeInput
                                 max        = { 100 }
                                 value      = { control.look ? eval (control.look) (value) : value }
                                 onTouchEnd = { () => controlCommand (value, isEnable) }
                                 onMouseUp  = { () => controlCommand (value, isEnable) }
                                 onChange   = { event => setValue (Number (event.target.value)) }
                              />
                           </Box>
                        </Box>
                     </ControlFabric>
                  }) }
               </Box>
            }

         </CardBody>
      </Card>
   )
})
import React, { useEffect, useState } from "react"

import { TCustomBlock    } from "models/ControlPanel"
import { TTarget         } from "models/ControllerState"
import { TSample         } from "models/Sample"

import { getSample       } from "api/sample"
import { devicesStore    } from "store/DevicesStore"

import { Box, Button, CardBody, CardFooter, CheckBox, Text } from "grommet"
import { Modal           } from 'components/Library/Modal/Modal'
import { IndicationChart } from 'components/IndicationBlocks/components/components/IndicationChart'
import { Time            } from "grommet-icons"

export const TargetScheduleEnable = ({
   block,
   targetValue,
   setEdit,
   sendCommand,
   disabled
}: {
   block      : TCustomBlock,
   targetValue: TTarget,
   setEdit    : (b: boolean) => void,
   sendCommand: (target: TTarget) => void,
   disabled   : boolean,
}) => {

   const [sample, setSample] = useState <TSample> ();
   const [modal , setModal ] = useState <boolean> ();

   const tag  = devicesStore.tags.find (tag => tag.id_tag === Number (block.tag))

   useEffect (() => { 

      const getLastSample = () => {
         if (devicesStore.tags.some (tag => tag.id_tag === Number (block.tag))) {
            getSample (devicesStore.tags.find (tag => tag.id_tag === Number (block.tag))?.id_tag)
            .then (sample => setSample (sample))
         }
      }

      if (block && devicesStore.devices?.length > 0) getLastSample ()
   }, [devicesStore.devices, block])

   //console.log (toJS (devicesStore.tags))

   return (
      <>
         <CardBody>

            { targetValue ? (
               <>
                  <Text
                     textAlign = 'center'
                     size      = "large"
                     margin    = { { vertical: "xsmall" } }
                  >
                     { (targetValue?.time1 != undefined && targetValue?.value1 != undefined) 
                     ? `C ${ targetValue?.time1}:00 - ${ targetValue?.value1} ${ block?.sign }`
                     : 'не установлено' }
                     <br />
                     { (targetValue?.time2 != undefined && targetValue?.value2 != undefined)
                     ? `C ${ targetValue?.time2}:00 - ${ targetValue?.value2} ${ block?.sign }`
                     : 'не установлено' }
                  </Text>
                  { devicesStore.tags.some (tag => tag.id_tag === Number (block?.tag)) &&
                     <Box
                        direction = 'row'
                        justify = 'center'
                     >
                        <Text
                           textAlign = 'center'
                           size      = "large"
                           margin    = { { vertical: "xsmall" } }
                           color     = 'active-text'
                        >
                           { 'Текущее показание: ' + ((devicesStore.tags.some (tag => tag.id_tag === Number (block?.tag)))
                        ? sample?.sample + ' ' + block?.sign
                        : 'нет данных') }
                           <Button
                              primary
                              icon = { <Time /> }
                              margin = { { horizontal: '10px' } }
                              onClick    = { () => setModal (true) }
                           />
                        </Text>
                     </Box>
                  }
               </>
            ) : (
               <Text
                  textAlign = 'center'
                  size      = "large"
                  margin    = { { vertical: "medium" } }
               >
                  Расписание не задано
               </Text>
            ) }

         </CardBody>

         <CardFooter
            justify = 'between'
            margin  = { { top: "small" } }
         >
            { /* <ControlFabric
               disabled = { disabled }
            > */ }
            <CheckBox
               checked  = { targetValue?.isenable }
               onChange = { e =>  sendCommand ({ ...targetValue, isenable: e.target.checked }) }
               toggle
            />
            { /* </ControlFabric> */ }

            <Button
               primary
               reverse
               label   = "Изменить"
               onClick = { () => setEdit (true) }
            />

         </CardFooter>

         { devicesStore.tags.some (tag => tag.id_tag === Number (block?.tag)) &&
            <Modal
               modal      = { modal }
               setModal   = { setModal }
               heading    = { 'Показания параметра "' + tag?.name + '"' }
            >
               <IndicationChart
                  tag = { tag }
               />
            </Modal>
         }
      </>
   )
}
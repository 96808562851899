import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import { rootStore } from 'store/RootStore';

import { AllRoutesRender } from 'AllRoutesRender';
import { isOuter } from 'data/routes';

import { Loader } from 'components/Common/Loader';
import { Login } from './containers/Auth/Login';

import { getLocalStorageItem } from 'utils/localStorage';
import { ACCESS_TOKEN } from 'constants/globalConstants';

const isExistToken = getLocalStorageItem (
   ACCESS_TOKEN,
   null
)

export const RouteChecker = observer (() => {
   
   const { pathname } = useLocation ()

   useEffect(() => {
      if (isExistToken) rootStore.currentUserStore.getCurrentUserData ()
   }, [])

   if (rootStore.currentUserStore.currentUserIsLoading) {
      return <Loader />
   }

   if (!rootStore.currentUserStore.currentUserExists &&
       !isOuter (pathname)                           &&
       !isExistToken) {
      return <Navigate to = '/login' />
   }

   return (
      <>
         { isOuter (pathname) 
           ? <Login /> 
           : <AllRoutesRender /> 
         }
      </>
   )
})

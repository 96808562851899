import { makeAutoObservable } from 'mobx';

import { TGroup, TControlBlock, TCustomBlock, TIndicationBlock } from 'models/ControlPanel';
import { projectStore } from './ProjectsStore';
import { flatAll } from 'utils/flatAll';
import { poolCP } from 'data/poolCP';
import { heatingCP } from 'data/heatingCP';
import { ventilationCP } from 'data/ventilationCP';
import { eshkafCP } from 'data/eshkafCP';

export class ControlPanelStore {

   controlBlocks  : TControlBlock[] = []
   groups         : TGroup[] = []
   targetBlocks   : TCustomBlock[] = []
   targetSchedules: TCustomBlock[] = []
   currentBlocks  : TIndicationBlock[] = []
   sheduleOptions = []
   loading = false

   constructor () {
      makeAutoObservable (this)
   }

   getControllerOptions (IdProject) {

      this.loading  = true

      if (projectStore?.projects?.length > 0) {
         
         const config = projectStore?.projects.find (project => project.id_project === IdProject)?.config
         let projectConfig
         
         if (typeof config === 'string') projectConfig = JSON.parse (config)

         if (projectConfig) {
            //@ts-ignore
            this.groups          = projectConfig.outputs.filter (blocks => blocks.type == 'Group' )
            this.controlBlocks   = flatAll (projectConfig.outputs).filter (blocks => blocks.type == 'ControlBlock')
            this.targetBlocks    = flatAll (projectConfig.outputs).filter (blocks => blocks.type == 'TargetBlock' )
            this.currentBlocks   = projectConfig.inputs
            this.sheduleOptions  = flatAll (projectConfig.outputs).filter (blocks => blocks.type == 'ControlBlock')
            this.targetSchedules = flatAll (projectConfig.outputs).filter (blocks => blocks.type == 'TargetSchedule')
         }
      }

      this.loading = false
   }

   getControlBlockByCommand (command: number) {

      return this.controlBlocks
      .find    (controlBlock => controlBlock.controls
      .flatMap (control => control.options)
      .find    (option => option.command == command))
   }

   getIndicationBlockByCommand (command: number) {

      return this.targetBlocks
      .find    (indicationBlock => indicationBlock.controls
      .flatMap (control => control.options)
      .find    (option => option.command == command))
   }

   getControlByCommand (command: number) {

      return this.controlBlocks
      .flatMap (controlBlock => controlBlock.controls)
      .find    (control => control.options
      .find    (option => option.command == command))
   }

   getIndicationByCommand (command: number) {

      return this.targetBlocks
      .flatMap (indicationBlock => indicationBlock.controls)
      .find    (control => control.options
      .find    (option => option.command == command))
   }

   getControlOptionByCommand (command: number) {

      return this.controlBlocks
      .flatMap (controlBlock => controlBlock.controls)
      .flatMap (control => control.options)
      .find    (option => option.command == command)
   }

   getIndicationOptionByCommand (command: number) {

      return this.targetBlocks
      .flatMap (indicationBlock => indicationBlock.controls)
      .flatMap (control => control.options)
      .find    (option => option.command == command)
   }
}

export const controlPanelStore = new ControlPanelStore ()
import React, { useState, useContext } from 'react'

import { observer } from 'mobx-react'

import { devicesStore } from 'store/DevicesStore'
import { settingsStore } from 'store/SettingsStore'
import { controlPanelStore } from 'store/ControlPanelStore'

import { timingOption, weekDaysOption } from 'data/schedules'
import { TSetting } from 'models/Setting'

import { 
   Box,
   Button,
   Card,
   CardBody,
   CardFooter,
   CardHeader,
   Heading,
   ResponsiveContext,
   Text,
   Tip
} from 'grommet'
import { Modal } from 'components/Library/Modal/Modal'

import { ToggleSchedule } from './components/ToggleSchedule'
import { ManipulateSchedule } from './components/ManipulateSchedule'
import { DeleteSchedule } from './components/DeleteSchedule'

import { Add, Edit, Package, StatusGoodSmall, Trash } from 'grommet-icons'

import { Loader } from 'components/Common/Loader'

export const Schedule = observer (({ setting }: {setting: TSetting}) => {

   const size     = useContext (ResponsiveContext)
   const settings = setting?.settings

   const [activeModal, setActiveModal] = useState (false)
   const [deleteModal, setDeleteModal] = useState (false)
   const [idTask     , setIdTask     ] = useState <number>(null)

   return (
      <>
         <Card
            border = { { color: 'brand', size: 'xsmall' } }
            pad    = "medium"
            flex   = "grow"
            margin = { { top: 'medium' } }
         >
            <CardHeader
               direction = 'row'
               align     = 'center'
               justify   = 'between'
               margin    = { { bottom: 'medium' } }
            >

               <Box
                  justify   = 'start'

                  direction = 'row'
                  align     = 'center'
                  gap       = 'small'
               >
                  { (setting?.ddeliveredsettings > setting?.dsettings)
                     ? (
                        <Tip content = "Расписание получено контроллером">
                           <StatusGoodSmall color = 'success'/>
                        </Tip>
                     ) : (
                        <Tip content = "Статус получения расписания контроллером неизвестен">
                           <StatusGoodSmall color = 'warning'/>
                        </Tip>
                     )
                  }

                  <Heading
                     level  = { 3 }
                     margin = { { top: 'none', bottom: 'none' } }
                     fill
                  >
                     { (!devicesStore.loading && devicesStore.devices?.length > 0) &&
                        `Расписание компонента "${devicesStore.devices
                        .flatMap (device => device.units)
                        .find    (unit => unit.id_unit === setting.id_unit)?.name}"`
                     }
                  </Heading>
               </Box>

               <Button
                  primary
                  onClick = { () => {
                     setIdTask      (null)
                     setActiveModal (true)
                  } }
                  icon = { <Add /> }
                  tip = 'Создать задачу для этого компонента'
                  fill = { false }
               />
               

            </CardHeader>

            <CardBody
               style = { { gap: 12 } }
            >

               { settingsStore.loading 
                 ? <Loader />
                 : <>
                    { settings?.Schedule 
                        ? settings?.Schedule.map (task => (

                           <Box
                              key       = { task.Id }
                              direction = 'row'
                              pad       = 'medium'
                              justify   = 'between'
                              border    = { { color: 'brand', size: 'xsmall' } }
                              style     = { { gap: 24 } }
                              wrap      = { size == 'small' ? true : false }
                              round
                           >

                              <ToggleSchedule
                                 idUnit = { setting.id_unit }
                                 idTask = { task.Id }
                              />

                              <Box
                                 alignSelf = 'center'
                              >
                                 { 'Выполнить команду "' }
                                 { /* actionOption[schedule?.Action].label */ }
                                 { task?.Action == 0 &&
                                   (controlPanelStore.getControlOptionByCommand (task?.Command)
                                   ?.schedule ??
                                   controlPanelStore.getControlOptionByCommand (task?.Command)
                                   ?.label)
                                 }

                                 { task?.Action == 1 && task?.EventName    }
                                 { task?.Action == 2 && task?.FunctionName }
                                 { '"' }

                                 { (task?.Value != undefined &&
                                    controlPanelStore.sheduleOptions
                                    .flatMap (data => data?.controls).find (control => control.options
                                    .find    (option  => option.command == task?.Command))?.type === 'RangeInput') &&
                                       ': ' + task?.Value + '%, ' 
                                 }

                                 {
                                    ' для ' + controlPanelStore.getControlBlockByCommand (task?.Command)?.label
                                 }

                                 { ' ' + timingOption[task?.Interval].label.toLowerCase() }

                                 { task?.Interval == 0 && '. ' }
                                 { task?.Interval == 1 && ' в ' + task?.EveryDay  + '. ' }
                                 { task?.Interval == 2 &&
                                    <>
                                       { ' в ' + task?.EveryWeek + '. Дни недели: ' }
                                       { task?.WeekDays.map ((day, i) => (
                                          weekDaysOption[day].label + ((i == (task?.WeekDays?.length - 1)) ? '. ' : ', ')
                                       )) }
                                    </>
                                 }

                                 { 'Начать: ' + new Date (task?.DStart).toLocaleString([], { dateStyle: 'long', timeStyle: 'short' }) }
                                 { '.' }
                              </Box>

                              <Box
                                 direction = 'row'
                                 alignSelf = 'center'
                                 flex      = 'grow'
                                 justify   = { size == 'small' ? 'between' : 'end' }
                                 wrap
                              >
                                 <Box>
                                    <Button
                                       icon    = { <Edit color = 'brand' /> }
                                       onClick = { () => {
                                          setIdTask      (task.Id)
                                          setActiveModal (true)
                                       } }
                                    />
                                 </Box>
                                 <Box>
                                    <Button
                                       icon    = { <Trash color = 'error' /> }
                                       onClick = { () => {
                                          setIdTask      (task.Id)
                                          setDeleteModal (true)
                                       } }
                                    />
                                 </Box>

                              </Box>
                           </Box>
                        )) : (
                      <Box
                         align   = "center"
                         justify = "center"
                      >
                         <Package />
                         <Text>Нет задач</Text>
                      </Box>
                      ) }
                 </>
               }

            </CardBody>

            <CardFooter />
         </Card>

         <Modal
            modal    = { activeModal }
            setModal = { setActiveModal }
            heading  = { idTask ? 'Редактировать задачу' : 'Новая задача' }
         >
            <ManipulateSchedule
               idUnit   = { setting.id_unit }
               idTask   = { idTask }
               setModal = { setActiveModal }
            />
         </Modal>

         <Modal
            modal    = { deleteModal }
            setModal = { setDeleteModal }
            heading  = 'Удалить задачу'
         >
            <DeleteSchedule
               idUnit   = { setting.id_unit }
               idTask   = { idTask }
               setModal = { setDeleteModal }
            />
         </Modal>
      </>
   )
})
import React, { useCallback, useMemo, useState } from "react"
import { observer } from "mobx-react"
import { useParams } from "react-router-dom"

import { controlPanelStore    } from "store/ControlPanelStore"
import { controllerStateStore } from "store/ControllerStateStore"
import { TCustomBlock         } from "models/ControlPanel"

import { Button, CardBody, CardFooter, TextInput } from "grommet"
import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"

import { setControl           } from "components/Library/ControlFabric/hooks/setControl"

export const TargetValue = observer (({
   block,
   targetValue,
   setEdit
}: {
   block      : TCustomBlock,
   targetValue: number,
   setEdit    : (boolean) => void
}) => {

   const { idProject } = useParams ()

   const valueCommand = useMemo (() => block.controls
   .flatMap (control => control.options).find (option => /Значение/.test (option.label)).command,
   [block.controls])

   const [wait , setWait ] = useState (false)
   const [value, setValue] = useState <number> (targetValue)

   const setIndication = useCallback (async (command, value?) => {

      controllerStateStore.setCommand (Number (idProject), block.alias, command, value)

      setControl ({
         name: controlPanelStore.getControlByCommand         (command)?.id
         ??    controlPanelStore.getIndicationBlockByCommand (command)?.id,
         command: command,
         value  : value
      }, setWait)

      setEdit (false)
   }, [idProject, controlPanelStore.controlBlocks, controlPanelStore.targetBlocks])

   return (
      <>
         <CardBody
            margin = { { vertical: "xsmall" } }
         >
            <TextInput
               placeholder = 'Установите целевое значение'
               type        = 'number'
               value       = { value }
               onChange    = { event => setValue (Number (event.target.value)) }
            />
         </CardBody>

         <CardFooter
            justify = 'between'
         >
            <Button
               secondary
               label   = 'Отмена'
               margin  = { { top: 'small' } }
               onClick = { () => setEdit (false) }
            />

            <ControlFabric wait = { wait } >
               <Button
                  primary
                  label   = 'Применить'
                  margin  = { { top: 'small' } }
                  onClick = { () => setIndication (valueCommand, value) }
               />
            </ControlFabric>

         </CardFooter>
      </>
   )
})